<template>
  <div>
    <div class="d-flex">
      <nav id="breadcrumbs" class="mb-3">
        <ul>
          <li>
            <router-link :to="{ name: 'company.dashboard' }">
              <i class="uil-home-alt"></i> <span>
              </span>
            </router-link>
          </li>
          <li>{{ $t("users.users") }}</li>
          <li>{{ $t("general.user_list") }}</li>
        </ul>
      </nav>
    </div>

    <div v-if="successMessage" class="card">
      <div class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color:green;">
          <i class="uil-exclamation-triangle"></i>
          {{ $t("general.update_success") }}
        </p>
      </div>
    </div>

    <div class="section-header mb-lg-2 border-0 uk-flex-middle">
      <div class="section-header-left">
        <h2 class="uk-heading-line text-left">
          <span> {{ $t("users.users") }} </span>
        </h2>
      </div>
      <div class="section-header-right">
        <button class="btn btn-default dropdown-arrow" type="button">
          <i class="uil-plus"></i> {{ $t("users.add_user") }}
        </button>
        <div uk-dropdown>
          <ul class="uk-nav uk-dropdown-nav">
            <li class="uk-active">
              <a @click="
                $router.push({
                  name: 'company.user.add',
                  params: { type: '1' },
                })
                "><i class="uil-user-plus"></i> {{ $t("users.add_manuel") }}</a>
            </li>
            <li class="uk-nav-divider"></li>
            <li>
              <a @click="
                $router.push({
                  name: 'company.user.add',
                  params: { type: '2' },
                })
                ">
                <i class="uil-table"></i> Excel ile Kullanıcı Ekle
              </a>
            </li>
            <li>
              <a @click="
                $router.push({
                  name: 'company.user.add',
                  params: { type: '3' },
                })
                "><i class="uil-chat-bubble-user"></i>
                {{ $t("users.hr_integration_add_user") }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--Modal Profie-->

    <!--End Modal Profile -->
    <div class="card">
      <!-- Card header -->
      <div class="card-header actions-toolbar border-0">
        <div class="d-flex justify-content-between align-items-center">
          <!-- <h4 class="d-inline-block mb-0"></h4> -->
          <!-- <div>
            <a aria-expanded="false" class="btn btn-icon btn-circle" href="javascript:void(0)">
              <i class="uil-search"></i>
            </a>
            <div class="uk-drop" style="left: 91px; top: 159.297px; z-index:10; display: inherit">
                <span class="uk-search uk-search-navbar uk-width-1-1">
                  <input v-model="filter.search" :placeholder="$t('general.search')" autofocus=""
                         class="uk-search-input shadow-0 uk-form-small" type="search">
                </span>
            </div>
          </div> -->
          <div class="row" style="width: 100%">
            <div class=" col-md-1  search-icon ">
              <i class="uil-search"></i>
            </div>
            <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
              <input class="uk-search-input shadow-0 uk-form-small " type="search" v-model="filter.search"
                :placeholder="$t('general.search')" v-on:keydown.enter.prevent="triggerUserFilterSearch" />
            </div>
          </div>
          <div class="d-flex">
            <!-- <div class="float-right d-flex"> -->
              <div class="btn-group mr-3">
                <select class="ml-1" tabindex="-98" @change="filterResult()" v-model="per_page">
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                  <option value="2500">2500</option>
                </select>
              </div>
            <!-- </div> -->
            <a :uk-tooltip="$t('filters.order.sort')" aria-expanded="false" class="btn btn-icon btn-hover  btn-circle"
              href="javascript:void(0)" title="">
              <i class="uil-list-ui-alt"></i>
            </a>
            <div class="uk-dropdown" uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up">
              <ul class="uk-nav uk-dropdown-nav">
                <li class="uk-active">
                  <a href="javascript:void(0)" @click="sortOrder('asc', 'name')">{{ $t("sorting.a_z") }}</a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="sortOrder('desc', 'name')">{{ $t("sorting.z_a") }}</a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="sortOrder('asc', 'id')">
                    {{ $t("sorting.created_at_old") }}</a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="sortOrder('desc', 'id')">
                    {{ $t("sorting.created_at_new") }}</a>
                </li>
              </ul>
            </div>

            <!-- Filter -->
            <a class="btn btn-icon btn-hover  btn-circle" href="javascript:void(0)"
              :uk-tooltip="$t('general.filter_list')">
              <i class="uil-filter"></i>
            </a>
            <UserFilter @getFilter="getFilter($event)"></UserFilter>

            <!-- Export -->
            <a class="btn btn-icon btn-hover  btn-circle" href="#" :uk-tooltip="$t('filters.export_excell')"
              @click="exportUsers">
              <i class="fa-file-excel fa"></i>
            </a>
          </div>
        </div>
      </div>
      <!-- Table -->
      <default-loading v-if="user_loading"></default-loading>
      <template v-else>
        <div class="d-flex justify-content-between mr-3 ml-3">
          <div>
            <span class="btn btn-outline-dark btn-sm mr-2">{{ this.$t('general.active_users') }} : {{
              counts.active_users_count }}</span>
            <span class="btn btn-outline-dark btn-sm mr-2">{{ this.$t('general.inactive_users') }} : {{
              counts.passive_users_count }}</span>
            <span class="btn btn-outline-dark btn-sm mr-2">{{ this.$t('general.Total Users') }} : {{
              counts.total_users_count }}</span>
          </div>
          <div>
            {{ this.$t('general.number_of_selected_users') }}: <i class="uil-users-alt"></i>
            {{ selectedUsersCount }}
          </div>
        </div>
        <div class="p-3 d-flex  disabled_hover">
          <button type="button" class="btn btn-sm btn-soft-secondary mr-2 mr-auto p-2" @click="allUserToggle()">
            <i class="uil-check-square"></i> {{ selectAllUserText }}
          </button>
          <button class="btn btn-sm btn-soft-success" type="button" @click="activeSelectedUsers()">
            <i class="uil-check"></i> {{ this.$t('general.activate_selected_users') }}
          </button>
          <button class="btn btn-sm btn-soft-danger ml-2" type="button" @click="passiveSelectedUsers()">
            <i class="uil-minus-circle"></i> {{ this.$t('general.inactive_selected_users') }}
          </button>
          <button class="btn btn-sm btn-soft-secondary ml-2" type="button" @click="deleteSelectedUsers()">
            <i class="uil-trash"></i> {{ this.$t('general.delete_selected_users') }}
          </button>
        </div>
        <div class="table-responsive">
          <table class="table align-items-center">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">
                  {{ $t("users.firstname") }} {{ $t("users.lastname") }}
                </th>
                <th scope="col">{{ $t("users.title") }}</th>
                <th scope="col">{{ $t("users.user_email") }}</th>
                <th scope="col">{{ $t("general.group_info") }}</th>
                <th scope="col">{{ $t("users.institution") }}</th>
                <th scope="col">{{ $t("general.created_at") }}</th>
                <th scope="col">{{ $t("users.last_login_date") }}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody class="list">
              <template v-for="item in formattedItem">
                <tr>
                  <th>
                    <input v-model="item_checked" :id="'notification_checkbox_' + item.id" :value="item.id"
                      style="width: 15px; margin: 10px 10px 10px 0" type="checkbox" />
                  </th>
                  <th scope="row">
                    <div class="media align-items-center">
                      <div>
                        <div class="avatar-parent-child" style="width: max-content">
                          <img :src="item.photo_link" alt="Image placeholder" class="avatar  rounded-circle" />
                          <span :class="item.is_online ? 'bg-success' : 'bg-offline'
                            " class="avatar-child avatar-badge"></span>
                        </div>
                      </div>
                      <div class="media-body ml-4">
                        <a class="name h6 mb-0 text-sm" @click="openProfileModal(item)">{{
                          item.fullname }}</a>
                        <small class="d-block font-weight-bold">#{{ item.id }}</small>
                      </div>
                    </div>
                  </th>
                  <td>{{ item.title }}</td>
                  <td>{{ item.email }}</td>
                  <td>
                    <div v-for="(group, index) in item.group_info" :key="index"
                      style="border-width:2px;text-align: center;border-color: #28a745;border-radius: 8px;border-style: solid !important;margin-bottom: 1px;">
                      {{ group.name }}
                    </div>
                    <!-- </div>{{ item.group_info }}-->
                  </td>
                  <td>{{ item.company_name }}</td>
                  <td>{{ item.created_at }}</td>
                  <td>{{ item.last_login_at }}</td>
                  <td class="text-right">
                    <!-- Actions -->
                    <div class="actions ml-3">
                      <a class="btn btn-icon btn-hover btn-sm btn-circle" uk-tooltip="Kullanıcının Profili"
                        @click="openProfileModal(item)">
                        <i class="uil-user "></i>
                      </a>
                      <a v-if="superAdmin()" class="btn btn-icon btn-hover btn-sm btn-circle"
                        uk-tooltip="Kullanıcı Hesabına Giriş" @click="openUserAccount(item)">
                        <i class="uil-eye "></i>
                      </a>
                      <a v-if="item.status_id !== 0" class="btn btn-icon btn-hover btn-sm btn-circle" type="button"
                        uk-tooltip="Kullanıcıyı Eğitime Ata" @click="openAssignmentModal(item.id)">
                        <i class="uil-location-arrow text-success"></i>
                      </a>
                      <a v-if="item.status_id !== 0" @click="openGroupModal(item.id)" type="button"
                        class="btn btn-icon btn-hover btn-sm btn-circle" uk-tooltip="Gruba Ata">
                        <i class="uil-user-square"></i>
                      </a>
                      <a v-if="item.status_id === 0" :uk-tooltip="$t('general.set_active')" aria-expanded="false"
                        class="btn btn-icon btn-hover btn-sm btn-circle" title="" @click="statusChange(item.id, 0)">
                        <i class="uil-minus-circle text-danger"></i>
                      </a>
                      <a v-else :uk-tooltip="$t('general.set_passive')" aria-expanded="false"
                        class="btn btn-icon btn-hover btn-sm btn-circle" title="" @click="statusChange(item.id, 1)">
                        <i class="uil-check text-success"></i>
                      </a>
                      <a aria-expanded="false" class="btn btn-icon btn-hover btn-sm btn-circle" uk-tooltip="Sil"
                        @click="deleteUser(item.id)">
                        <i class="uil-trash text-danger"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div id="assignment_modal" class="uk-flex-top" uk-modal>
          <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title">Atama Seç</h2>
            </div>
            <div class="uk-modal-body">
              <default-loading v-if="assignment_loading"></default-loading>
              <template v-else>
                <table class="uk-table uk-table-middle uk-table-divider"
                  v-if="assignments.length !== 0 && assignments[0]">
                  <thead>
                    <tr>
                      <th>Atama İsmi</th>
                      <th>{{ $t('ajadam.end_date') }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(assignment, index) in assignments" :key="index">
                      <td>{{ assignment.name }}</td>
                      <td>{{ assignment.end_date }}</td>
                      <td class="text-right">
                        <button @click="assignmentCreate(assignment.id)" class="uk-button uk-button-default"
                          type="button">
                          {{ $t('menu.company_dashboard.create_assignment') }}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="uk-alert-warning" uk-alert v-else>
                  <p>Atama yapılabilecek kayıt bulunmuyor.</p>
                </div>
              </template>
            </div>
            <div class="uk-modal-footer d-flex justify-content-end">
              <a href="javascript:;" class="btn btn-danger uk-modal-close">Kapat</a>
            </div>
          </div>
        </div>
        <div id="group_modal" class="uk-flex-top" uk-modal v-if="groups?.length > 0">
          <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title">{{ $t('general.choose_group') }}</h2>
            </div>
            <div class="uk-modal-body" style="max-height: 500px;overflow-y: scroll">
              <default-loading v-if="group_loading"></default-loading>
              <template v-else>
                <table class="uk-table uk-table-middle uk-table-divider" v-if="groups.length != 0">
                  <thead>
                    <tr>
                      <th>Grup İsmi</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(group, index) in groups" :key="index">
                      <td>{{ group.name }}</td>
                      <td class="text-right">
                        <button @click="groupCreate(group.id)" class="uk-button uk-button-default" type="button">
                          {{ $t('menu.company_dashboard.create_assignment') }}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="uk-alert-warning" uk-alert v-else>
                  <p>Atama yapılabilecek kayıt bulunmuyor.</p>
                </div>
              </template>
            </div>
            <div class="uk-modal-footer d-flex justify-content-end">
              <a href="javascript:;" class="btn btn-danger uk-modal-close">Kapat</a>
            </div>
          </div>
        </div>
        <pagination :pagination="pagination" class="mt-2 mb-3" @changePage="setPage($event)"></pagination>
      </template>
    </div>
    <UserProfile v-if="formattedUserDetail && activeItem" :item="activeItem" :user_detail="formattedUserDetail">
    </UserProfile>
  </div>
</template>

<script>
import SecureLS from "secure-ls";
const ls = new SecureLS({ encodingType: "aes", isCompression: true });

import module, {
  BASE_URL,
  ERROR,
  GET_ITEMS,
  GET_COUNTS,
  COUNTS,
  ITEMS,
  LOADING,
  MODULE_NAME,
  SUCCESS,
  UPDATE_USER,
} from "@/core/services/store/user.module";

import user_detail_module, {
  GET_ITEM as GET_USER_DETAIL,
  ITEM as USER_DETAIL,
  MODULE_NAME as USER_DETAIL_MODULE,
} from "@/core/services/store/user_detail.module";

import assignment_module, {
  GET_ITEM as GET_ASSIGNMENT,
  ITEM as ASSIGNMENT_ITEM,
  MODULE_NAME as ASSIGNMENT_MODULE,
} from "@/core/services/store/assignment.module";

import group_module, {
  GET_ITEM as GET_GROUP,
  ITEM as GROUP_ITEM,
  MODULE_NAME as GROUP_MODULE,
} from "@/core/services/store/user_group.module";

import store from "@/core/services";
import moment from "moment";
import axios from "axios";
import Pagination from "@/assets/components/Pagination";
import UserProfile from "@/view/company/User/UserProfile";
import UserFilter from "@/view/company/User/Filter";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import company_module, {
  ONLINE_USERS,
  MODULE_NAME as COMPANY_MODULE,
} from "@/core/services/store/company.module";

const _USER_DETAIL_MODULE = USER_DETAIL_MODULE;
const _MODULE_NAME = MODULE_NAME;
const _ASSIGNMENT_MODULE = ASSIGNMENT_MODULE;
const _GROUP_MODULE = GROUP_MODULE;
const _COMPANY_MODULE = COMPANY_MODULE;

export default {
  name: "Users",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_MODULE_NAME, module);
    registerStoreModule(_USER_DETAIL_MODULE, user_detail_module);
    registerStoreModule(_ASSIGNMENT_MODULE, assignment_module);
    registerStoreModule(_GROUP_MODULE, group_module);
    registerStoreModule(_COMPANY_MODULE, company_module);
  },
  components: { Pagination, UserProfile, UserFilter, DefaultLoading },
  data() {
    return {
      pagination: null,
      activeItem: null,
      activeUser: null,
      selectAllUserText: "Tümünü İşaretle",
      filters: {},
      page: 1,
      per_page: 10,
      sort_by: "id",
      sort_desc: "desc",
      filter: {
        search: null,
        order: "desc",
        sort: "id",
        company_id: null,
      },
      assignment_loading: null,
      group_loading: null,
      successMessage: "",
      headers: this.apiHeaders,
      item_checked: [],
      temp_page: [],
      checked_user_page: [],
      keepFilter: null
    };
  },
  computed: {
    user_header: {
      get() {
        return JSON.parse(this.secureLocalStorage("user"));
      },
      set() {
      },
    },
    onlineUsers() {
      return store.getters[_COMPANY_MODULE + "/" + ONLINE_USERS];
    },
    user_detail: {
      get() {
        return store.getters[USER_DETAIL_MODULE + "/" + USER_DETAIL];
      },
      set(value) { },
    },
    assignments: {
      get() {
        return store.getters[ASSIGNMENT_MODULE + "/" + ASSIGNMENT_ITEM];
      },
      set(value) { },
    },
    groups: {
      get() {
        return store.getters[GROUP_MODULE + "/" + GROUP_ITEM];
      },
      set(value) { },
    },
    items: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEMS];
      },
      set(value) { },
    },
    counts: {
      get() {
        return store.getters[MODULE_NAME + "/" + COUNTS];
      },
      set(value) { },
    },
    user_loading: {
      get() {
        return store.getters[MODULE_NAME + "/" + LOADING];
      },
      set(value) { },
    },
    errors: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
    success() {
      return store.getters[MODULE_NAME + "/" + SUCCESS];
    },
    formattedItem() {
      let results = [];
      if (this.items === null || !("data" in this.items)) return [];
      this.pagination = this.items;
      this.items.data.forEach((item) => {
        results.push({
          id: item.id,
          name: item.name,
          surname: item.surname,
          dob: item.dob ? item.dob : "-",
          email: item.email,
          group_info: item.groups,
          phone: item.phone,
          company_id: item.company_id,
          company_name: item.company ? item.company.name : "-",
          position_id: item.position_id,
          title_id: item.title_id,
          region_id: item.region_id,
          department_id: item.department_id,
          branch_id: item.branch_id,
          status_id: item.status_id,
          is_online: this.onlineUsers.find(
            (onlineUser) => onlineUser.user == item.id && onlineUser.status
          )
            ? true
            : false,
          photo_link: item.photo_link,
          fullname: [item.name, item.surname].join(" "),
          title: item.title ? item.title.name : "-",
          login_check_field: item.login_check_field
            ? item.login_check_field
            : "-",
          created_at: item.created_at
            ? moment(item.created_at).format("DD/MM/YYYY HH:mm:ss")
            : "-",
          last_login_at: item.last_login_at
            ? moment(item.last_login_at).format("DD/MM/YYYY HH:mm:ss")
            : "-",
        });
        //  item.groups.forEach((group) => {
        //     results[results.length - 1].group_info ? results[results.length - 1].group_info += ' ' + group.name : results[results.length - 1].group_info = group.name;
        //   });
      });

      return results;
    },
    formattedUserDetail() {
      if (this.user_detail === null) return null;
      return this.user_detail;
    },
    selectedUsersCount() {
      let userCount = this.item_checked.length;
      return userCount;
    },
  },
  methods: {
    openUserAccount(event) {
      let UserInfo = {
        "target_user_id": event.id
      }
      axios.post('api/impersonate',
        UserInfo, {
        headers: this.headers
      })
        .then((response) => {
          let token = ls.get("token");
          this.$router.push({ path: "/dashboard" });
          if (!ls.get("superAdminToken")) ls.set("superAdminToken", token);
          ls.set("token", response.data.access_token);

        })
    },
    superAdmin() {
      if (this.user_header === null || !("roles" in this.user_header)) {
        return false;
      }
      if (this.user_header.roles.length > 2) {
        return true;
      } else {
        return false;
      }
    },
    allUserToggle() {
      let self = this;
      // this.userChangeCheckItem()
      let found = this.checked_user_page.find(
        (element) => element == this.items.current_page
      );

      if (
        this.temp_page.find((r) => r === this.items.current_page) == undefined
      ) {
        this.formattedItem.forEach((item) => {
          $("#notification_checkbox_" + item.id).prop("checked", true);

          let checked_status = this.item_checked.find((r) => r === item.id);

          if (!checked_status) self.item_checked.push(item.id);
        });

        if (!found) {
          this.checked_user_page.push(this.items.current_page);
        }

        this.selectAllUserText = "Tümünü Kaldır";
        this.temp_page.push(this.items.current_page);
      } else {
        this.formattedItem.forEach((item) => {
          $("#notification_checkbox_" + item.id).prop("checked", false);
          this.item_checked = this.item_checked.filter(function (checked_user) {
            return checked_user !== item.id;
          });
        });
        this.checked_user_page = this.checked_user_page.filter(function (
          checked_page
        ) {
          return checked_page !== found;
        });
        this.selectAllUserText = "Tümünü İşaretle";
        this.temp_page.forEach((e, i) => {
          if (e == self.items.current_page) {
            self.temp_page.splice(i, 1);
          }
        });
      }
    },

    deleteSelectedUsers() {
      let self = this;
      if (self.item_checked.length > 0) {
        self.item_checked.forEach((element) => {
          self.deleteUser(element);
        });
        self.item_checked = [];
      }
    },
    passiveSelectedUsers() {
      let self = this;
      if (self.item_checked.length > 0) {
        self.item_checked.forEach((element) => {
          self.statusChange(element, 1);
        });
        self.item_checked = [];
      }
    },
    activeSelectedUsers() {
      let self = this;
      if (self.item_checked.length > 0) {
        self.item_checked.forEach((element) => {
          self.statusChange(element, 0);
        });
        self.item_checked = [];
      }
    },
    assignmentCreate(assignmentId) {
      this.assignment_loading = true;
      axios
        .get(`/api/attach-user-assignment/${this.activeUser}/${assignmentId}`, {
          headers: this.headers,
        })
        .then(() => {
          this.getAssignmentItem(this.activeUser);
          this.assignment_loading = false;
        });
    },
    groupCreate(groupId) {
      this.group_loading = true;
      axios
        .get(`/api/attach-user-group/${this.activeUser}/${groupId}`, {
          headers: this.headers,
        })
        .then(() => {
          this.getGroupItem(this.activeUser);
          this.group_loading = false;
        });
    },
    openAssignmentModal(userId) {
      UIkit.modal("#assignment_modal").show();
      this.activeUser = userId;
      this.getAssignmentItem(userId);
      this.assignment_loading = false;
    },
    openGroupModal(userId) {
      this.activeUser = userId;
      this.getGroupItem(userId);


    },
    getCounts() {
      this.$store
        .dispatch(MODULE_NAME + "/" + GET_COUNTS, {
          url: BASE_URL + "/counts",
        })
        .then(function (res) {
        });
    },
    filterResult(filter = null, page = 1) {
      let self = this;
      let filters = {
        like: self.filter.search,
        page: page,
        per_page: self.per_page,
        sort: self.filter.sort,
        order: self.filter.order,
        with_groups: true
      };
      // if (filter) {
      filter ? this.keepFilter = filter : filter = this.keepFilter;
      // }else {
      //   filter = this.keepFilter;
      // }
      if (filter) {
        filter.company_id ? filters['company_id'] = filter.company_id : null;
        filter.company_id = null;
        $.each(filter, function (key, value) {
          if (value && Array.isArray(filter[key])) {
            let ids = [];
            filter[key]?.forEach((items) => {
              ids.push(items.id);
            });
            filters[key] = JSON.stringify(ids)
          }
        });
      }
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEMS, {
        url: BASE_URL,
        filters: filters,
      });
      // UIkit.dropdown("#filterDropdown").hide();
    },
    onClickEdit(id) {
      if (id !== this.parentMemberId) {
        let routeData = this.$router.resolve({
          name: "EditUser",
          params: { id: id },
        });
        window.open(routeData.href, "_blank");
      }
    },
    sortOrder(order, sort) {
      this.page = 1;
      this.filter.order = order;
      this.filter.sort = sort;

      this.filterResult();
    },
    setPage(page) {
      this.page = page;

      let found = this.checked_user_page.find((element) => element == page);
      if (found) {
        this.selectAllUserText = "Tümünü Kaldır";
      } else {
        this.selectAllUserText = "Tümünü İşaretle";
      }

      // this.page = page;
      this.filterResult(this.filter.company_id ? { company_id: this.filter.company_id } : null, page);
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    statusChange(id, status_id) {
      store.dispatch(MODULE_NAME + "/" + UPDATE_USER, {
        url: BASE_URL + "/status/" + id,
        contents: {
          user: id,
          status_id: status_id,
        },
        returnType: "stateless",
        acceptPromise: true,
        _method: "put",
      }).then(() => {
        setTimeout(() => {
          this.getCounts();

        }, 100);
      });
    },
    deleteUser(id) {
      axios
        .delete(`/api/user/${id}`, {
          headers: this.headers,
        })
        .then((response) => {
          this.filterResult();
        });
    },
    getUserDetail(id) {
      store.dispatch(USER_DETAIL_MODULE + "/" + GET_USER_DETAIL, {
        url: "api/user/detail-admin" + "/" + id,
      }).then((data) => {
        UIkit.modal("#modal-profile").show();
      })
    },
    getAssignmentItem(userId) {
      store.dispatch(ASSIGNMENT_MODULE + "/" + GET_ASSIGNMENT, {
        url: `api/user-assignments/${userId}`,
      });
    },
    getGroupItem(userId) {
      store.dispatch(GROUP_MODULE + "/" + GET_GROUP, {
        url: `api/user-groups/${userId}`,
      }).then((response) => {
        if (response) {
          UIkit.modal("#group_modal").show();
        }
      });
    },
    getFilter(value) {
      this.page = 1;
      this.filter.company_id = value.company_id;

      this.filterResult(value);
    },
    exportUsers() {
      let config = {
        responseType: "blob",
        headers: this.apiHeaders,
      };

      let data = {
        company_id: this.filter.company_id,
      };

      axios
        .post(
          process.env.VUE_APP_BACKEND_APP_URL + "/api/user/export",
          data,
          config
        )
        .then((response) => {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");

          fileLink.href = fileURL;
          let day = moment().format("YYYYMMDDHHMMSS");
          let fileName = "users_list_" + day + ".xlsx";
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
        });
    },
    openProfileModal(value) {
      if (value) {
        this.activeItem = value;

        this.getUserDetail(value.id);
      }
    },
  },
  mounted() {
    this.selectAllUserText = "Tümünü İşaretle";
    this.filterResult();
    this.getCounts();
    this.debouncedFilterSearch = _.debounce(this.filterResult, 500);
    if (this.$route.params.succesMessage != null) {
      this.successMessage = this.$route.params.succesMessage;
      this.scrollToTop();
    }
  },
  watch: {
    success: function (val) {
      if (val) {
        this.filterResult();
      }
    },
    "filter.search": function (val) {
      this.page = 1;

      this.debouncedFilterSearch();
    },
    // activeItem(value) {
    //   if (value) {
    //     this.getUserDetail(value.id);
    //   }
    // },
  },
  beforeDestroy() {
    const modal = UIkit.modal("#modal-profile");
    if (modal) modal.$destroy(true);

    const modalGroup = UIkit.modal("#group_modal");
    if (modalGroup) modalGroup.$destroy(true);

    const modalAssignment = UIkit.modal("#assignment_modal");
    if (modalAssignment) modalAssignment.$destroy(true);
  },
};
</script>

<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.uk-alert {
  margin: 0;
}

.bg-offline {
  background-color: #c0c0c0 !important;
}
</style>
